/* eslint-disable object-shorthand */

export const PACKAGE_BUILD_DATE = '2025-01-23T03:57:19.474Z';
export const PACKAGE_NAME = 'invision-customercare';
export const PACKAGE_VERSION = '25.1.10100-1695940';

export default {
    PACKAGE_BUILD_DATE: PACKAGE_BUILD_DATE,
    PACKAGE_NAME: PACKAGE_NAME,
    PACKAGE_VERSION: PACKAGE_VERSION
};
